.selected-background {
    background-color: #0cd1e8;
}

@media only screen and (max-width: 600px) {
    body {
        font-size: 0.8em;
    }
}

h2 ion-icon {
    vertical-align: middle;
}

h2 .small {
    font-size: 0.75em;
}

h3 .small {
    font-size: 0.75em;
}

.graficon th, .graficon td {
    border: 1px solid black;
    margin: 1px;
    line-height: 1;
}
.graficon {
    margin: 0 10px;
    border-collapse: collapse;
}
.graficon tr td:first-child {
    padding: 0 10px;
    text-align: left;
}
.graficon tr td, .graficon tr th {
    padding: 0 5px;
    text-align: center;
}
.graficon tr.sticky-header {
    position: sticky;
    z-index: 2;
    top:0;
}
.graficon tr.sticky-header th {
    position: sticky;
    z-index: 2;
    top:0;
    background-color: wheat;
    padding: 3px 2px;
}

td.done {
    color: white;
    background-color: #79b063;
}
td.part {
    background-color: rgba(238, 250, 110, 0.56);
}

td.none {
    background-color: rgba(255, 75, 75, 0.42);
}

.logbook-table tr.incomplete {
  background-color: rgba(250, 100, 100, 0.42);;
}

.btn-link {
    color: #0b81ec;
    cursor: pointer;
}
.btn-link:hover {
    text-decoration: underline;
}

.refresh-wrapper {
    position: relative;
}
.refresh-wrapper ion-icon {
    font-size: 30px;
    position: absolute;
    top: -8px;
}
.refresh-wrapper small {
    position: absolute;
    top: 5px;
    left: 3px;
    width: 25px;
    display: block;
    text-align: center;
    font-size: 8px;
}

